import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AxiosGlobalConfiguration } from 'api';
import ThemedPageMetadata from 'components/App/ThemedPageMetadata';
import Modals from 'components/Modals';
// TODO don't import this here
import { ToastContainer } from 'components/notification';
import { GA_TRACKING_ID } from 'config';
import { AuthProvider } from 'state/auth';
import { EventTrackingProvider } from 'state/eventTracking';

import theme from 'theme';
import CSSReset from './CSSReset';
import ErrorBoundary from './ErrorBoundary';
import PageMetadata from './PageMetadata';
import QueryClientProvider from './QueryClientProvider';
import { RouteProvider } from './Router';
import ThemeProvider from './ThemeProvider';

if (!GA_TRACKING_ID) {
  throw new Error('missing GA tracking id');
}

ReactGA.initialize(GA_TRACKING_ID);

const App: React.FC = () => (
  <HelmetProvider>
    <PageMetadata />
    <CSSReset />
    <QueryClientProvider>
      <EventTrackingProvider>
        <SkeletonTheme
          baseColor={theme.palette.l4}
          highlightColor={theme.palette.l3}
        >
          <AuthProvider>
            <AxiosGlobalConfiguration />
            <ThemeProvider>
              <ErrorBoundary>
                <ToastContainer newestOnTop />
                <ThemedPageMetadata />
                <Modals />
                <RouteProvider />
              </ErrorBoundary>
            </ThemeProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </SkeletonTheme>
      </EventTrackingProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
