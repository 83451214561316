import {
  BlockheadTheme,
  createBreakpoints,
  darkTheme as defaultThemeDark,
  defaultTheme,
} from '@sparemin/blockhead';
import { DefaultTheme } from 'styled-components';

function createTheme(baseTheme: BlockheadTheme = defaultTheme): DefaultTheme {
  return {
    ...baseTheme,
    backgroundColorMain: baseTheme.palette.l1,
    footerFadeHeight: 120,
    headerHeightDesktop: 70,
    breakpoints: createBreakpoints({
      ...defaultTheme.breakpoints.values,
      md: 900,
    }),
  };
}

const theme = createTheme(defaultTheme);

export const darkTheme = createTheme(defaultThemeDark);

export default theme;
