import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';

import { DislikeClipSuggestionReason } from 'api';
import { MIXPANEL_API_HOST, MIXPANEL_DEBUG, MIXPANEL_TOKEN } from 'config';
import { ThirdPartyIntegrator } from 'types';
import { omitNil } from 'utils/objects';
import { ERRORS } from '../constants';
import {
  EventTrackingContextType,
  HighlightPopperClickProps,
  HighlightPopperSelectionProps,
  SpeakerLabelSaveProps,
  TrackErrorNotification,
  TrackExportButtonClick,
  TrackExport,
  TrackForgotPasswordClick,
  TrackHighlightPopperClicked,
  TrackHighlightPopperShown,
  TrackOpenProject,
  TrackSpeakerLabelClick,
  TrackSpeakerLabelSave,
  TrackTranscriptPageLoaded,
  TrackTranscriptPageGatedLoaded,
  TrackTranscriptPageLoading,
  TrackTranscriptPageTranscribing,
  TrackUploadComplete,
  TrackUploadStart,
  TrackUserLogin,
  TrackUserSignup,
  TrackWaveformRevealed,
  TrackWaveformPercentageChange,
  TrackUnlockThisFeature,
  TrackSaveChangesToChapter,
  UnlockThisFeatureProps,
  TrackReplaceTitleWithSuggestion,
  TrackSignupPageLoaded,
  TrackLoginPageLoaded,
  TrackSignUpError,
  TrackLoginError,
  TrackUseSuggestedClip,
  TrackDismissSuggestedClip,
  TrackCopyShowNotes,
  TrackCopyEddyProjectURL,
  TrackDownloadPromoPack,
  TrackIntegratorModalSelection,
  TrackClickUploadLimitBanner,
  TrackPromoPackPopperShown,
  TrackPromoPackPopperClicked,
  TrackPromoPackPopperClickedProps,
  TrackPromoPackModalAction,
  PromoPackModalAction,
  PromoPackActionAssetType,
  PromoPackActionArtStyle,
  TrackClickPodcastSearch,
  TrackClickPodcastName,
  TrackClickEpisodeName,
  SuggestedClipButtonClick,
  CopyEddyProjectURLAsset,
  TrackRegenerateAIOutputProps,
  TrackRegenerateAIOutput,
  SaveChangesToChapterProps,
  TrackAiSelections,
  AiSelectionOptions,
} from '../types';
import { EVENTS } from './constants';
import {
  track,
  setUserTrackingInfo,
  clearUserTrackingInfo,
  isFileError,
  booleanToAffirmativeValue,
} from './utils';

if (!MIXPANEL_TOKEN) {
  throw new Error('missing mixpanel token');
}

mixpanel.init(MIXPANEL_TOKEN, {
  api_host: MIXPANEL_API_HOST,
  batch_requests: false,
  debug: MIXPANEL_DEBUG,
});

export default function useMixpanelEventTracking(): EventTrackingContextType {
  const trackUserSignup: TrackUserSignup = useCallback((email) => {
    track(EVENTS.signup, { email });
    mixpanel.alias(email);
  }, []);

  const trackSignUpPageLoaded: TrackSignupPageLoaded = useCallback(() => {
    track(EVENTS.signupPageLoaded);
  }, []);

  const trackSignUpError: TrackSignUpError = useCallback(() => {
    track(EVENTS.signupError);
  }, []);

  const trackUserLogin: TrackUserLogin = useCallback(
    (email) => track(EVENTS.login, { email }),
    [],
  );

  const trackLoginPageLoaded: TrackLoginPageLoaded = useCallback(() => {
    track(EVENTS.loginPageLoaded);
  }, []);

  const trackLoginError: TrackLoginError = useCallback(() => {
    track(EVENTS.loginError);
  }, []);

  const trackForgotPasswordClick: TrackForgotPasswordClick = useCallback(
    () => track(EVENTS.forgotPassword),
    [],
  );

  const trackUploadStart: TrackUploadStart = useCallback(
    (props) => track(EVENTS.uploadBegins, props),
    [],
  );

  const trackUploadComplete: TrackUploadComplete = useCallback(
    (props) => track(EVENTS.uploadCompleted, props),
    [],
  );

  const trackOpenProject: TrackOpenProject = useCallback(
    (projectName) => track(EVENTS.openProject, { projectName }),
    [],
  );

  const trackTranscriptPageTranscribing: TrackTranscriptPageTranscribing =
    useCallback(() => track(EVENTS.transcriptPageTranscribing), []);

  const trackTranscriptPageLoading: TrackTranscriptPageLoading = useCallback(
    () => track(EVENTS.transcriptPageLoading),
    [],
  );

  const trackTranscriptPageLoaded: TrackTranscriptPageLoaded = useCallback(
    (props) => {
      track(EVENTS.transcriptPageLoaded, props);
    },
    [],
  );

  const trackTranscriptPageGatedLoaded: TrackTranscriptPageGatedLoaded =
    useCallback(() => track(EVENTS.trackTranscriptPageGatedLoaded), []);

  const trackHighlightPopperShown: TrackHighlightPopperShown = useCallback(
    (props: HighlightPopperSelectionProps) =>
      track(EVENTS.highlightPopperShown, props),
    [],
  );

  const trackHighlightPopperClicked: TrackHighlightPopperClicked = useCallback(
    (props: HighlightPopperClickProps) =>
      track(EVENTS.highlightPopperClicked, props),
    [],
  );

  const trackReplaceTitleWithSuggestion: TrackReplaceTitleWithSuggestion =
    useCallback(() => track(EVENTS.replaceTitleWithSuggestion), []);

  const trackSpeakerLabelClick: TrackSpeakerLabelClick = useCallback(
    () => track(EVENTS.speakerLabelClick),
    [],
  );

  const trackSpeakerLabelSave: TrackSpeakerLabelSave = useCallback(
    (props: SpeakerLabelSaveProps) => track(EVENTS.speakerLabelSave, props),
    [],
  );

  const trackExportButtonClick: TrackExportButtonClick = useCallback(
    () => track(EVENTS.exportButtonClicked),
    [],
  );

  const trackExport: TrackExport = useCallback(
    (props) => track(EVENTS.export, props),
    [],
  );

  const trackErrorNotification: TrackErrorNotification = useCallback(
    (errorCode, message) => {
      const code = isFileError(errorCode) ? errorCode.code : errorCode;
      const type = isFileError(errorCode) ? errorCode.type : undefined;

      track(
        EVENTS.errorNotification,
        omitNil({
          code,
          type,
          message: message ?? ERRORS[code],
        }),
      );
    },

    [],
  );

  const trackWaveformRevealed: TrackWaveformRevealed = useCallback(
    () => track(EVENTS.waveformRevealed),
    [],
  );

  const trackWaveformPercentageChange: TrackWaveformPercentageChange =
    useCallback(
      (percentage: number) =>
        track(EVENTS.waveformPercentageChange, { percentage }),
      [],
    );

  const trackUnlockThisFeature: TrackUnlockThisFeature = useCallback(
    (props: UnlockThisFeatureProps) => track(EVENTS.unlockThisFeature, props),
    [],
  );

  const trackSaveChangesToChapter: TrackSaveChangesToChapter = useCallback(
    (props: SaveChangesToChapterProps) =>
      track(EVENTS.saveChangesToChapter, props),
    [],
  );

  const trackUseSuggestedClip: TrackUseSuggestedClip = useCallback(
    (buttonClick: SuggestedClipButtonClick) =>
      track(EVENTS.useSuggestedClip, { buttonClick }),
    [],
  );

  const trackDismissSuggestedClip: TrackDismissSuggestedClip = useCallback(
    (reason: DislikeClipSuggestionReason) =>
      track(EVENTS.dismissSuggestedClip, { reason }),
    [],
  );

  const trackCopyShowNotes: TrackCopyShowNotes = useCallback(
    (chapters: boolean) => track(EVENTS.copyShowNotes, { chapters }),
    [],
  );

  const trackCopyEddyProjectURL: TrackCopyEddyProjectURL = useCallback(
    (asset: CopyEddyProjectURLAsset) =>
      track(EVENTS.copyEddyProjectURL, {
        asset,
      }),
    [],
  );

  const trackPromoPackModalAction: TrackPromoPackModalAction = useCallback(
    (
      buttonClick: PromoPackModalAction,
      assetType: PromoPackActionAssetType,
      artStyle?: PromoPackActionArtStyle,
    ) =>
      track(EVENTS.promoPackModalAction, { buttonClick, assetType, artStyle }),
    [],
  );

  const trackRegenerateAIOutput: TrackRegenerateAIOutput = useCallback(
    (props: TrackRegenerateAIOutputProps) =>
      track(EVENTS.regenerateAIOutput, { ...props }),
    [],
  );

  const trackDownloadPromoPack: TrackDownloadPromoPack = useCallback(
    () => track(EVENTS.downloadPromoPack),
    [],
  );

  const trackPromoPackPopperShown: TrackPromoPackPopperShown = useCallback(
    () => track(EVENTS.promoPackPopperShown),
    [],
  );

  const trackPromoPackPopperClicked: TrackPromoPackPopperClicked = useCallback(
    (props: TrackPromoPackPopperClickedProps) =>
      track(EVENTS.promoPackPopperClicked, props),
    [],
  );

  const trackIntegratorModalSelection: TrackIntegratorModalSelection =
    useCallback(
      (source: ThirdPartyIntegrator) =>
        track(EVENTS.integratorModalSelection, { source }),
      [],
    );

  const trackClickUploadLimitBanner: TrackClickUploadLimitBanner =
    useCallback(() => {
      track(EVENTS.trackClickUploadLimitBanner);
    }, []);

  const trackClickPodcastSearch: TrackClickPodcastSearch = useCallback(() => {
    track(EVENTS.trackClickPodcastSearch);
  }, []);

  const trackClickPodcastName: TrackClickPodcastName = useCallback(() => {
    track(EVENTS.trackClickPodcastName);
  }, []);

  const trackClickEpisodeName: TrackClickEpisodeName = useCallback(() => {
    track(EVENTS.trackClickEpisodeName);
  }, []);

  const trackAiSelections: TrackAiSelections = useCallback(
    (values: AiSelectionOptions) => {
      const {
        nameProject,
        addChapters,
        removeFillerWords,
        personalizeToPodcast,
      } = values;
      track(EVENTS.aiSelections, {
        nameProject: booleanToAffirmativeValue(nameProject),
        addChapters: booleanToAffirmativeValue(addChapters),
        removeFillerWords: booleanToAffirmativeValue(removeFillerWords),
        personalizeToPodcast: personalizeToPodcast ?? 'No',
      });
    },
    [],
  );

  return useMemo(
    () => ({
      setUserTrackingInfo,
      clearUserTrackingInfo,
      trackCopyShowNotes,
      trackCopyEddyProjectURL,
      trackPromoPackModalAction,
      trackRegenerateAIOutput,
      trackDownloadPromoPack,
      trackPromoPackPopperShown,
      trackPromoPackPopperClicked,
      trackIntegratorModalSelection,
      trackDismissSuggestedClip,
      trackUserSignup,
      trackSignUpPageLoaded,
      trackSignUpError,
      trackUserLogin,
      trackLoginPageLoaded,
      trackLoginError,
      trackForgotPasswordClick,
      trackUploadStart,
      trackUploadComplete,
      trackOpenProject,
      trackReplaceTitleWithSuggestion,
      trackTranscriptPageTranscribing,
      trackTranscriptPageLoaded,
      trackTranscriptPageGatedLoaded,
      trackTranscriptPageLoading,
      trackHighlightPopperShown,
      trackHighlightPopperClicked,
      trackSpeakerLabelClick,
      trackSpeakerLabelSave,
      trackExportButtonClick,
      trackExport,
      trackErrorNotification,
      trackUseSuggestedClip,
      trackWaveformRevealed,
      trackWaveformPercentageChange,
      trackUnlockThisFeature,
      trackSaveChangesToChapter,
      trackClickUploadLimitBanner,
      trackClickPodcastSearch,
      trackClickPodcastName,
      trackClickEpisodeName,
      trackAiSelections,
    }),
    [
      trackCopyShowNotes,
      trackCopyEddyProjectURL,
      trackPromoPackModalAction,
      trackRegenerateAIOutput,
      trackDownloadPromoPack,
      trackPromoPackPopperShown,
      trackPromoPackPopperClicked,
      trackIntegratorModalSelection,
      trackDismissSuggestedClip,
      trackUserSignup,
      trackSignUpPageLoaded,
      trackSignUpError,
      trackUserLogin,
      trackLoginPageLoaded,
      trackLoginError,
      trackForgotPasswordClick,
      trackUploadStart,
      trackUploadComplete,
      trackOpenProject,
      trackReplaceTitleWithSuggestion,
      trackTranscriptPageTranscribing,
      trackTranscriptPageLoaded,
      trackTranscriptPageGatedLoaded,
      trackTranscriptPageLoading,
      trackHighlightPopperShown,
      trackHighlightPopperClicked,
      trackSpeakerLabelClick,
      trackSpeakerLabelSave,
      trackExportButtonClick,
      trackExport,
      trackErrorNotification,
      trackUseSuggestedClip,
      trackWaveformRevealed,
      trackWaveformPercentageChange,
      trackUnlockThisFeature,
      trackSaveChangesToChapter,
      trackClickUploadLimitBanner,
      trackClickPodcastSearch,
      trackClickPodcastName,
      trackClickEpisodeName,
      trackAiSelections,
    ],
  );
}
