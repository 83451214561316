import React from 'react';
import { useIsNotificationsDisabled } from 'state/notification';
import * as S from './styles';

export type ToastContainerProps = React.ComponentPropsWithoutRef<
  typeof S.ToastContainer
> & {
  children?: React.ReactNode;
};

const ToastContainer: React.FC<ToastContainerProps> = ({
  children,
  ...props
}) => {
  const isNotificationsDisabled = useIsNotificationsDisabled();

  return isNotificationsDisabled ? null : <S.ToastContainer {...props} />;
};

export default ToastContainer;
