import { ResetPasswordCard } from '@sparemin/auth';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import ThemedRoute from 'components/ThemedRoute';
import UnauthenticatedRoute from 'components/UnauthenticatedRoute';
import { LoginCard, RegistrationCard } from 'pages/AuthenticationPage';
import paths from 'paths';
import AppLayout from './AppLayout';
import {
  AuthenticationPage,
  CreateProjectPage,
  EmbedTranscriptEditorPage,
  LazyDarkPage,
  NativeTranscriptEditorPage,
  ProjectsPage,
  QueryStringAuthRoute,
  TranscriptOwnerRoute,
} from './lazyRoutes';
import ResponsiveLayoutSwitcher from './ResponsiveLayoutSwitcher';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route element={<ResponsiveLayoutSwitcher />}>
        <Route path="*" element={<Navigate to={paths.dashboard.value} />} />
        <Route element={<QueryStringAuthRoute />}>
          {/*
           *  CreateProjectPage does not necessarily have to be wrapped in
           *  AuthenticatedRoute, however it acts as a failsafe and also makes it
           *  clear that the route is not designed to be accessed anonymously
           */}
          <Route element={<AuthenticatedRoute />}>
            <Route
              path={paths.projectCreate.value}
              element={<CreateProjectPage />}
            />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ThemedRoute />}>
            <Route path={paths.dashboard.value} element={<ProjectsPage />} />
            <Route element={<TranscriptOwnerRoute />}>
              <Route
                path={paths.editor.value}
                element={<NativeTranscriptEditorPage />}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<LazyDarkPage />}>
          <Route element={<QueryStringAuthRoute />}>
            <Route element={<AuthenticatedRoute />}>
              <Route
                path={paths.embed.value}
                element={<EmbedTranscriptEditorPage />}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<UnauthenticatedRoute />}>
          <Route element={<AuthenticationPage />}>
            <Route path={paths.login.value} element={<LoginCard />} />
            <Route
              path={paths.registration.value}
              element={<RegistrationCard />}
            />
            <Route
              path={paths.resetPassword.value}
              element={<ResetPasswordCard />}
            />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

export default router;
