import { ThemeProvider } from '@sparemin/blockhead';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Helmet from 'components/Helmet';
import { darkTheme } from 'theme';
import LazyRouteFallback from './LazyRouteFallback';

export interface LazyDarkPageProps {}

/**
 * For lazy routes that have to render in a dark theme.  This component provides
 * both the dark theme and the Suspense component to render the lazy route.
 *
 * The root theme provider for the application is a light theme.  The LazyRouteFallback
 * component uses the theme to color the page background during loading of async
 * chunks.  If the dark-theme ThemeProvider isn't above the LazyRouteFallback, the
 * loading screen will render in the light theme while the rest of the route renders
 * in the dark theme.  This component fixes that.
 */
const LazyDarkPage: React.FC<LazyDarkPageProps> = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <style id="bg">
          {`
        body {
          background-color: ${darkTheme.backgroundColorMain};
        } 
      `}
        </style>
      </Helmet>
      <Suspense fallback={<LazyRouteFallback />}>
        <Outlet />
      </Suspense>
    </ThemeProvider>
  );
};

export default LazyDarkPage;
