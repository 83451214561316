import { TokenResponse } from '@sparemin/auth';
import { request } from 'api';
import { APPLICATION_NAME } from 'config/constants';
import { USER_V1_URL, AUTH_V1_URL } from './constants';
import {
  GetUserIntegratorsArgs,
  GetUserIntegratorsResult,
  GetUserProfileArgs,
  GetUserProfileResult,
} from './types';

export async function getUserProfile({ userId, token }: GetUserProfileArgs) {
  const { data } = await request.get<GetUserProfileResult>(
    `${USER_V1_URL}/${userId}/profile`,
    {
      omitBearerPrefix: true,
      manualToken: token,
    },
  );

  return data;
}

export async function renewToken() {
  const { data } = await request.post<TokenResponse>(
    `${AUTH_V1_URL}/token`,
    {
      application: APPLICATION_NAME,
    },
    { omitBearerPrefix: true },
  );

  return data;
}

export async function getUserIntegrators({ userId }: GetUserIntegratorsArgs) {
  const { data } = await request.get<GetUserIntegratorsResult>(
    `${USER_V1_URL}/${userId}/integrators`,
    {
      omitBearerPrefix: true,
    },
  );

  return data;
}
