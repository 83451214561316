export function boolean(val: string | undefined, fallback: boolean) {
  return val === undefined ? fallback : val.trim().toLowerCase() === 'true';
}

export function array(val: string | undefined): string[];
export function array<TType>(
  val: string | undefined,
  formatter: (val: string) => TType,
): TType[];
export function array(
  val: string | undefined,
  formatter?: (val: string) => unknown,
): unknown[] {
  if (!val) {
    return [];
  }

  const parts = val.split(',');

  return formatter ? parts.map(formatter) : parts;
}
