import styled from 'styled-components';
import Page from 'components/Page';
import TakeoverView from 'components/TakeoverView';

export const Root = styled(TakeoverView).attrs({
  as: Page,
})`
  align-items: center;
  display: flex;
  justify-content: center;
`;
