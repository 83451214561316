import { useEffect, useRef } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { actionsSelector, isDisabledSelector } from './selectors';
import { NotificationState, NotificationStore } from './types';

const defaultState: NotificationState = {
  isDisabled: false,
};

const useNotificationStore = create(
  immer<NotificationStore>((set) => ({
    ...defaultState,
    actions: {
      disableNotifications() {
        set((store) => {
          store.isDisabled = true;
        });
      },
      enableNotifications() {
        set((store) => {
          store.isDisabled = false;
        });
      },
    },
  })),
);

export const useNotificationActions = () =>
  useNotificationStore(actionsSelector);

export const useNotificationSubscription = (
  cb: (current: NotificationStore, prev: NotificationStore) => void,
) => {
  useEffect(() => useNotificationStore.subscribe(cb), [cb]);
};

export const useIsNotificationsDisabled = () =>
  useNotificationStore(isDisabledSelector);

export const useDisableNotifications = () => {
  const { enableNotifications, disableNotifications } =
    useNotificationActions();

  const isDisabled = useRef(useIsNotificationsDisabled());

  useEffect(() => {
    if (!isDisabled.current) {
      disableNotifications();

      return enableNotifications;
    }
  }, [disableNotifications, enableNotifications, isDisabled]);
};
