import { PulseLoader } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export interface LazyRouteFallbackProps {}

const LazyRouteFallback: React.FC<LazyRouteFallbackProps> = () => (
  <S.Root>
    <PulseLoader fadeIn />
  </S.Root>
);

export default LazyRouteFallback;
