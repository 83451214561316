import { Suspense } from 'react';
import { RouterProvider as ReactRouterProvider } from 'react-router-dom';
import { LazyRouteFallback } from './lazyRoutes';
import router from './router';

export interface RouterProviderProps {}

const RouterProvider: React.FC<RouterProviderProps> = () => (
  <Suspense fallback={<LazyRouteFallback />}>
    <ReactRouterProvider router={router} />
  </Suspense>
);

export default RouterProvider;
