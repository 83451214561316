import { createAuthHeader } from '@sparemin/auth';
import { InternalAxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';
import { Interceptor } from './types';

export default function authHeaderRequestInterceptor(
  token: string | undefined,
): Interceptor<InternalAxiosRequestConfig> {
  const onFulfilled = (config: InternalAxiosRequestConfig) => {
    const { disableAuthHeaderInterceptor, omitBearerPrefix, manualToken } =
      config;

    const spareminToken = manualToken ?? token;
    if (!spareminToken || disableAuthHeaderInterceptor) {
      return config;
    }

    return merge(
      config,
      {
        headers: {
          Authorization: createAuthHeader(spareminToken, !omitBearerPrefix),
        },
      },

      manualToken && { disableAuthHeaderInterceptor: true },
    );
  };

  const onRejected = Promise.reject;

  return [onFulfilled, onRejected];
}
