import { produce } from 'immer';
import { last } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { create } from 'zustand';

import { GetModalParams, ModalNames } from './modals';
import {
  actionsSelector,
  hasModalSelector,
  isModalOpenSelector,
  modalsStackSelector,
} from './selectors';
import { ModalsStore } from './types';

const defaultState = {
  modalsStack: [],
};

const useModalsStore = create<ModalsStore>()((set) => ({
  ...defaultState,
  actions: {
    hideModal: () =>
      set(
        produce((draft: ModalsStore) => {
          if (draft.modalsStack.length) {
            draft.modalsStack[0].show = false;
          }
        }),
      ),
    popModal: () =>
      set(
        produce((draft: ModalsStore) => {
          draft.modalsStack.shift();
        }),
      ),
    pushModal: (modal) =>
      set(
        produce((draft: ModalsStore) => {
          draft.modalsStack.push({ ...modal, show: true });
        }),
      ),
    reset: () => {
      set(defaultState);
    },
  },
}));

export const useModalsActions = () => useModalsStore(actionsSelector);

export const useHasModal = () => useModalsStore(hasModalSelector);

export const useHasVisibleModal = () =>
  useModalsStore(
    useCallback((state) => {
      const top = last(modalsStackSelector(state));

      return top?.show;
    }, []),
  );

export const useModalIsInStack = (modalName: ModalNames) =>
  useModalsStore(
    useCallback(
      (state) => !!state.modalsStack.find((modal) => modal.name === modalName),
      [modalName],
    ),
  );

export const useModalIsOpen = (modalName: ModalNames) =>
  useModalsStore(
    useCallback((state) => isModalOpenSelector(state, modalName), [modalName]),
  );

export const useModalParams = <TName extends ModalNames>(
  modalName: TName,
): GetModalParams<TName> | undefined =>
  useModalsStore(
    useCallback(
      (state) => {
        const modalConfig = modalsStackSelector(state).find(
          (modal) => modal.name === modalName,
        );
        return modalConfig?.params;
      },
      [modalName],
    ),
  );

export function useModalsSubscription(
  cb: (current: ModalsStore, prev: ModalsStore) => void,
) {
  useEffect(() => useModalsStore.subscribe(cb), [cb]);
}
