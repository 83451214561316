import qs from 'query-string';
import { request } from 'api';
import { APPLICATION_NAME } from 'config';
import { omitNil } from 'utils/objects';
import {
  PLAN_V1_CHECKOUT_REDIRECTOR_URL,
  PLAN_V1_SUBSCRIPTION_URL,
} from './constants';
import {
  CheckoutUrlTargetPlan,
  GetMyPlanSubscriptionArgs,
  UserPlanSubscription,
} from './types';

export async function getMyPlanSubscription(args?: GetMyPlanSubscriptionArgs) {
  const params = {
    usageWindowEndMillis: args?.usageWindowEndMillis,
    usageWindowStartMillis: args?.usageWindowStartMillis,
  };
  const { data } = await request.get<UserPlanSubscription>(
    `${PLAN_V1_SUBSCRIPTION_URL}/me`,
    { manualToken: args?.token, params: omitNil(params) },
  );

  return data;
}

export function createCheckoutUrl(
  targetPlan: CheckoutUrlTargetPlan,
  userId: number,
) {
  return qs.stringifyUrl({
    url: PLAN_V1_CHECKOUT_REDIRECTOR_URL,
    query: {
      targetPlan,
      userId,
      application: APPLICATION_NAME,
    },
  });
}
