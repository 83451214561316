import { array, boolean } from './utils';

export const DEBUG = boolean(import.meta.env.VITE_APP_DEBUG, false);

export const DISCO_DISPLAY_VARIANT_ID = import.meta.env
  .VITE_APP_DISCO_DISPLAY_VARIANT_ID;

export const DISCO_SDK_URL = import.meta.env.VITE_APP_DISCO_SDK_URL;

export const DISCO_WIDGET_ID = import.meta.env.VITE_APP_DISCO_WIDGET_ID;

export const EDDY_SERVICE_URL = import.meta.env.VITE_APP_EDDY_SERVICE_URL;

export const HEADLINER_USER_SERVICE_URL = import.meta.env
  .VITE_APP_HEADLINER_USER_SERVICE_URL;

export const MEDIA_UPLOAD_SERVICE_URL = import.meta.env
  .VITE_APP_MEDIA_UPLOAD_SERVICE_URL;

export const IMAGE_SERVICE_URL = import.meta.env.VITE_APP_IMAGE_SERVICE_URL;

export const THIRD_PARTY_SERVICE_URL = import.meta.env
  .VITE_APP_THIRD_PARTY_SERVICE_URL;

export const PLAN_SERVICE_URL = import.meta.env.VITE_APP_PLAN_SERVICE_URL;

export const PODCAST_SERVICE_URL = import.meta.env.VITE_APP_PODCAST_SERVICE_URL;

export const TRANSCRIPT_SERVICE_URL = import.meta.env
  .VITE_APP_TRANSCRIPT_SERVICE_URL;

export const USER_SERVICE_URL = import.meta.env.VITE_APP_USER_SERVICE_URL;

export const URL_GENERATOR_SERVICE_URL = import.meta.env
  .VITE_APP_URL_GENERATOR_SERVICE_URL;

export const MOCK_API = boolean(import.meta.env.VITE_APP_MOCK_API, false);

// ga
export const GA_TRACKING_ID = import.meta.env.VITE_APP_GA_TRACKING_ID;

// apple
export const APPLE_AUTH_REDIRECT_URI = import.meta.env
  .VITE_APP_APPLE_AUTH_REDIRECT_URI;

// mixpanel
export const MIXPANEL_API_HOST = import.meta.env.VITE_APP_MIXPANEL_API_HOST;
export const MIXPANEL_TOKEN = import.meta.env.VITE_APP_MIXPANEL_TOKEN;
export const MIXPANEL_DEBUG = boolean(
  import.meta.env.VITE_APP_MIXPANEL_DEBUG,
  false,
);

// integrators
export const ENABLE_GOOGLE_DRIVE_INTEGRATOR = boolean(
  import.meta.env.VITE_APP_ENABLE_GOOGLE_DRIVE_INTEGRATOR,
  false,
);

export const ENABLE_ZOOM_INTEGRATOR = boolean(
  import.meta.env.VITE_APP_ENABLE_ZOOM_INTEGRATOR,
  false,
);

export const ENABLE_YOUTUBE_INTEGRATOR = boolean(
  import.meta.env.VITE_APP_ENABLE_YOUTUBE_INTEGRATOR,
  false,
);

export const POSTMESSAGE_TRUSTED_ORIGINS = array(
  import.meta.env.VITE_APP_POSTMESSAGE_TRUSTED_ORIGINS,
);
